$(document).ready(function () {
  ("use strict");

  // ajust alçada mapes
  $(window).resize(function () {
    var h = $('.centres-container').outerHeight();
    $('.map').height(h);
  }).resize();

  // ------
  // slider
  // ------
  $(".slider").slick({
    infinite: true,
    fade: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false
  });

  $(".home .slider.l .slider__caption").addClass("l");


  // -----------
  // Collapsible (https://www.w3schools.com/howto/howto_js_collapsible.asp)
  // -----------
  var coll = document.getElementsByClassName("collapsible");
  var i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    });
  }


  // Menú: afegim botó i ocultem menú quan hi ha js
  $("#menu")
    .before(
      '<button class="hamburger hamburger--squeeze" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="vh">Menú</span></button>'
    )
    .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");

  toggleMenu.addEventListener("click", function () {
    var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
    toggleMenu.setAttribute("aria-expanded", !open);
    menu.hidden = !menu.hidden;
  });

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    $("body").toggleClass("js-menu-open");
    // afegir classe al header
    $(".header-bg").toggleClass("no-bg");
  });


  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("is-visible");
    } else {
      $(".scrolltop").removeClass("is-visible");
    }
  });


});
